import { FAQsPanelProps } from "~/components/FAQsPanel/Props";
import { ContentstackFaqsDecorator } from "~/components/Faqs/ContentstackFaqsDecorator";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { FAQsPanel } from "~~/lib/types/Contentstack/GlobalFields/FAQsPanel";
import { FaqProps } from "~/components/Faqs/Props";

const contentstackFAQsPanelDecorator =
  () =>
  (FAQsPanel: FAQsPanel[]): FAQsPanelProps | undefined => {
    if (!FAQsPanel || !FAQsPanel.length) {
      return undefined;
    }

    const decoratedFAQsPanel: FaqProps[] = FAQsPanel.flatMap((faq) => {
      return ContentstackFaqsDecorator()([faq]);
    });

    return { faqs: decoratedFAQsPanel };
  };

export const ContentstackFAQsPanelDecorator = returnUndefinedOnDecoratorError(
  contentstackFAQsPanelDecorator,
  "ContentstackFAQsPanelDecorator"
);
