import { PageBannerProps } from "./Type";
import { Banner } from "~/lib/types/Contentstack";
import { imageMapper } from "~/lib/mappers/ImageMapper";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";

const bannerDecorator =
  () =>
  (bannerEntry: Banner): PageBannerProps | undefined => {
    const bannerImage =
      bannerEntry?.hero_image && bannerEntry?.hero_image?.length > 0
        ? bannerEntry?.hero_image[0]
        : undefined;

    return {
      title: bannerEntry?.display_name,
      image: bannerImage
        ? imageMapper(
            bannerImage.picture?.url ?? "",
            bannerImage.alt_text ?? "",
            bannerImage.picture?.dimension?.width,
            bannerImage.picture?.dimension?.height,
            {
              offset: "center",
              byAspectRatio: {
                xs: "96:25",
                sm: "96:25",
                md: "96:25",
                lg: "96:25",
                xl: "96:25",
              },
            }
          )
        : undefined,
    };
  };

export const BannerDecorator = returnUndefinedOnDecoratorError(
  bannerDecorator,
  "BannerDecorator"
);
