import { DecoratorOptions } from "../types/Options";
import { returnUndefinedOnDecoratorError } from "../utils/error/returnUndefinedOnDecoratorError";
import { Price } from "~/lib/types/Contentstack";
import { TripCardProps } from "~~/components/TripCard/Props";

const contentstackPriceDecorator =
  (options: DecoratorOptions) =>
  (priceEntry: Price): TripCardProps["price"] | undefined => {
    const currencyCode = options.currencyCode.toUpperCase() as keyof Price;
    const price = priceEntry[options.currencyCode.toLowerCase() as keyof Price];

    if (currencyCode && price && price > 0) {
      return {
        currencyCode,
        value: price,
      };
    }

    return undefined;
  };

export const ContentstackPriceDecorator = returnUndefinedOnDecoratorError(
  contentstackPriceDecorator,
  "ContentstackPriceDecorator"
);
