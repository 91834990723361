import { ContentstackUniqueSellingPointDecorator } from "../UniqueSellingPoint/ContentstackUniqueSellingPointDecorator";
import { UniqueSellingPointPanelProps } from "./Props";
import { UniqueSellingPoint } from "~~/lib/types/Contentstack";
import { isNotNull } from "~~/lib/utils/isNotNull";

export const ContentstackUniqueSellingPointPanelDecorator =
  () =>
  (
    uniqueSellingPoints: UniqueSellingPoint[]
  ): UniqueSellingPointPanelProps | undefined => {
    const decoratedUSPs = uniqueSellingPoints
      .map((uniqueSellingPoint) =>
        ContentstackUniqueSellingPointDecorator()(uniqueSellingPoint)
      )
      .filter(isNotNull);

    return { uniqueSellingPoints: decoratedUSPs };
  };
