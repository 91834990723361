import { FormPanelProps } from "./Props";
import { FormPanel } from "~~/lib/types/Contentstack/GlobalFields/FormPanel";

export const ContentstackFormPanelDecorator =
  () =>
  (formPanel: FormPanel): FormPanelProps | undefined => {
    if (!formPanel?.form_id) {
      return undefined;
    }

    return {
      formId: formPanel.form_id,
      submissionHeading: formPanel.submission_heading,
      submissionMessage: formPanel.submission_message,
    };
  };
