import { JsonRte } from "~~/lib/types/Contentstack";

export const extractTextFromJSONRte = (content: JsonRte) => {
  let text = "";
  if ("text" in content) {
    text = content.text;
  }
  if ("children" in content) {
    text += content.children
      ?.map((child) => extractTextFromJSONRte(child))
      .join("");
  }
  return text;
};
