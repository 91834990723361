export type WindowSizeType = {
  width: number;
  height: number;
  breakpoint: string;
};

export const enum WindowSizeBreakpoint {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}
