import { JsonRichTextProps } from "./Props";
import { JsonRte } from "~~/lib/types/Contentstack";
import { extractTextFromJSONRte } from "~~/lib/utils/contentstack/extractTextFromJSONRte";

export const JsonRichTextDecorator =
  () =>
  (richTextEntry: JsonRte): JsonRichTextProps | undefined => {
    if (richTextEntry && extractTextFromJSONRte(richTextEntry).length > 0) {
      return {
        json: richTextEntry,
      };
    }

    return undefined;
  };
