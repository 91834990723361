import { JsonRichTextDecorator } from "../JsonRichText/ContentstackJsonRichTextDecorator";
import { IntroductionProps } from "./Props";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { LeadInfo } from "~/lib/types/Contentstack";

const introductionDecorator =
  () =>
  (leadInfo: LeadInfo): IntroductionProps | undefined => {
    const introduction = {
      tagline: leadInfo && leadInfo.tagline ? leadInfo.tagline : undefined,
      longDescription:
        leadInfo && leadInfo.long_description
          ? JsonRichTextDecorator()(leadInfo.long_description)
          : undefined,
    };

    if (introduction.tagline || introduction.longDescription) {
      return introduction;
    }

    return undefined;
  };

export const IntroductionDecorator = returnUndefinedOnDecoratorError(
  introductionDecorator,
  "IntroductionDecorator"
);
