export enum BackgroundColor {
  Map = "#b4d6f4",
}

type Image = {
  src: string;
  alt: string;
  title?: string;
  height?: number;
  width?: number;
  srcSet?: {
    src: string;
    size: string;
  }[];
  backgroundColor?: BackgroundColor;
  link?: string;
};

export type BannerAspectRatio = "8:11" | "4:3" | "7:3" | "96:25";

export type ImageCropInfo = {
  offset:
    | "center"
    | {
        xPercentage: number;
        yPercentage: number;
      };
  byAspectRatio: {
    xs: BannerAspectRatio;
    sm: BannerAspectRatio;
    md: BannerAspectRatio;
    lg: BannerAspectRatio;
    xl: BannerAspectRatio;
  };
};

export default Image;
