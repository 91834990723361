import { stripHostNameFromURL } from "./stripHostNameFromURL";

export const replaceOriginInURL = (
  url: string,
  replacementDomainName: string
): string | undefined => {
  return `${replacementDomainName}/${stripHostNameFromURL(url)}`
    .replaceAll(/:\/\//g, ":##")
    .replaceAll(/\/\/\/?/g, "/")
    .replaceAll(/:##/g, "://");
};
