import { JsonRichTextDecorator } from "../JsonRichText/ContentstackJsonRichTextDecorator";
import { CallToActionPanelProps } from "./Props";
import { CallToAction } from "~/lib/types/Contentstack";
import { isThereValuesInObject } from "~~/lib/utils/isThereValuesInObject";

export const ContentstackCallToActionPanelDecorator =
  () =>
  (callToActionEntry: CallToAction): CallToActionPanelProps | undefined => {
    const decoratedCallToActionEntry = {
      displayName: callToActionEntry.display_name
        ? callToActionEntry.display_name
        : undefined,
      description: callToActionEntry.description
        ? JsonRichTextDecorator()(callToActionEntry.description)
        : undefined,
      action: callToActionEntry.action
        ? {
            title: callToActionEntry.action?.title,
            href: callToActionEntry.action?.href,
          }
        : undefined,
    };

    if (isThereValuesInObject(decoratedCallToActionEntry)) {
      return decoratedCallToActionEntry;
    }

    return undefined;
  };
