import { Breadcrumb } from "./Props";
import { extractBreadcrumbsFromPageEntry } from "~/lib/utils/extractBreadcrumbsFromPageEntry";
import {
  ModularPage,
  FaqPage,
  Category,
  Style,
  TravelAlertsPage,
  Cluster,
  Deal,
} from "~/lib/types/Contentstack";

export const PageBreadcrumbDecorator =
  () =>
  (
    pageEntry:
      | ModularPage
      | FaqPage
      | Category
      | Style
      | TravelAlertsPage
      | Cluster
      | Deal
  ): Breadcrumb => {
    const breadcrumb: Breadcrumb = {
      description: `Breadcrumb of ${pageEntry.lead_info.display_name}`,
      items: [...extractBreadcrumbsFromPageEntry(pageEntry)],
    };
    return breadcrumb;
  };
