/* eslint-disable @typescript-eslint/no-explicit-any */
import { loggerFactory, logTags } from "../logger/logger";
import { UnwrapPromise } from "~~/lib/types/Contentstack/System/UnwrapPromise";

const logger = loggerFactory({
  tags: [logTags.Function.ErrorBoundary],
});

export const returnUndefinedOnDecoratorError = <
  T extends (...args: any[]) => (...innerArgs: any[]) => any
>(
  fn: T,
  functionName: string
) => {
  return (...args: Parameters<T>) => {
    const decorator = fn(...args);

    const wrappedDecorator = function (
      this: {
        logger: typeof logger;
        decorator: typeof decorator;
      },
      ...innerArgs: Parameters<ReturnType<T>>
    ): UnwrapPromise<ReturnType<ReturnType<T>> | undefined> {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      try {
        const result = self.decorator(...innerArgs);
        if (result && result.then) {
          return result.catch((e: any) => {
            self.logger.warn(
              `Error occurred while invoking decorator: !!!functionName!!!`,
              {
                error: e,
              }
            );
          });
        } else {
          return result;
        }
      } catch (e) {
        self.logger.warn(
          `Error occurred while invoking decorator: !!!functionName!!!`,
          {
            error: e,
          }
        );
      }
    };

    // eslint-disable-next-line no-new-func
    return new Function(
      "logger",
      "decorator",
      `return (...args) => (${wrappedDecorator}).call({logger, decorator}, ...args)`.replaceAll(
        "!!!functionName!!!",
        functionName
      )
    )(logger, decorator);
  };
};
