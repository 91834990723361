/* eslint-disable @typescript-eslint/no-unused-vars */
import { DecoratorOptions } from "../types/Options";
import { LeadInfo } from "~/lib/types/Contentstack";
import { IntroductionDecorator } from "~~/components/Introduction/ContentstackIntroductionDecorator";
import { IntroductionProps } from "~~/components/Introduction/Props";
import { BannerDecorator } from "~~/components/PageBanner/ContentstackBannerDecorator";
import { PageBannerProps } from "~~/components/PageBanner/Type";

export const ContentstackLeadInfoDecorator =
  (options: DecoratorOptions) =>
  (leadInfoEntry: LeadInfo): IntroductionProps & PageBannerProps => ({
    ...BannerDecorator()(leadInfoEntry),
    ...IntroductionDecorator()(leadInfoEntry),
  });
