import { HeadingPanelProps } from "./Props";
import { Heading, HeadingTypes } from "~/lib/types/Contentstack";

export const ContentstackHeadingPanelDecorator =
  () =>
  (headingPanel: Heading): HeadingPanelProps | undefined => {
    if (
      !headingPanel?.heading_name ||
      !Object.values(HeadingTypes).includes(headingPanel?.heading_type)
    ) {
      return undefined;
    }

    return {
      tagName: headingPanel.heading_type,
      text: headingPanel.heading_name,
      includeInSubNavigation: headingPanel.include_in_sub_nav,
      subNavigationText: headingPanel.sub_nav_display_name,
    };
  };
