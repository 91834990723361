import { VideoProps } from "./Props";
import { Video } from "~~/lib/types/Contentstack";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { isFullyQualifiedLink } from "~~/lib/utils/link/isFullyQualifiedLink";

const contentstackVideoDecorator =
  () =>
  (videoEntry: Video): VideoProps | undefined => {
    if (videoEntry && videoEntry.url && isFullyQualifiedLink(videoEntry.url)) {
      return {
        url: videoEntry.url,
      };
    }

    return undefined;
  };

export const ContentstackVideoDecorator = returnUndefinedOnDecoratorError(
  contentstackVideoDecorator,
  "ContentstackVideoDecorator"
);
