import { MediaPanelProps } from "./Props";
import { MediaPanel } from "~/lib/types/Contentstack";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { imageMapper } from "~~/lib/mappers/ImageMapper";

const contentstackMediaPanelDecorator =
  () =>
  (mediaPanel: MediaPanel): MediaPanelProps | undefined => {
    const mediaPanelImages = mediaPanel.picture;
    if (!Array.isArray(mediaPanelImages)) {
      return undefined;
    }

    const filteredMediaPanel = mediaPanelImages.filter(
      (mediaPanelImage) => !!mediaPanelImage?.picture?.url
    );

    if (filteredMediaPanel.length === 0) {
      return undefined;
    }
    const images = filteredMediaPanel.map((mediaPanelImage) =>
      imageMapper(
        mediaPanelImage.picture?.url ?? "",
        mediaPanelImage.alt_text ?? "",
        mediaPanelImage.picture?.dimension?.width,
        mediaPanelImage.picture?.dimension?.height
      )
    );
    return {
      images,
    };
  };

export const ContentstackMediaPanelDecorator = returnUndefinedOnDecoratorError(
  contentstackMediaPanelDecorator,
  "ContentstackMediaPanelDecorator"
);
