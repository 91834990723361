import { UniqueSellingPointProps } from "./Props";
import { JsonRichTextDecorator } from "~/components/JsonRichText/ContentstackJsonRichTextDecorator";
import { UniqueSellingPoint } from "~~/lib/types/Contentstack/ContentTypes/UniqueSellingPoint";

export const ContentstackUniqueSellingPointDecorator =
  () =>
  (
    uniqueSellingPoint: UniqueSellingPoint,
    pageType?: string
  ): UniqueSellingPointProps | undefined => {
    if (
      !uniqueSellingPoint?.illustration?.illustration ||
      !uniqueSellingPoint?.lead_info?.display_name
    ) {
      return undefined;
    }

    const description =
      pageType === "product"
        ? uniqueSellingPoint.product_usp_description
          ? uniqueSellingPoint.product_usp_description
          : undefined
        : uniqueSellingPoint.lead_info.short_description
        ? JsonRichTextDecorator()(
            uniqueSellingPoint.lead_info.short_description
          )
        : undefined;

    return {
      icon: uniqueSellingPoint.illustration.illustration,
      title: uniqueSellingPoint.lead_info.display_name,
      description,
    };
  };
