import { DecoratorOptions } from "../types/Options";
import { ContentstackLeadInfoDecorator } from "./ContentstackLeadInfoDecorator";
import { Style } from "~/lib/types/Contentstack";

export const ContentstackStyleDecorator =
  (options: DecoratorOptions) => (styleEntry: Style) => {
    return {
      displayName: ContentstackLeadInfoDecorator(options)(styleEntry.lead_info)
        .title,
      url: styleEntry.url,
    };
  };
