import { ContentPanelDeviceColumnConfig, ContentPanelProps } from "./Props";
import { ContentPanelReferenceContentDecorator } from "./ContentPanelReferenceContentDecorator";
import { ContentPanelCustomContentDecorator } from "./ContentPanelCustomContentDecorator";
import { WindowSizeBreakpoint } from "~/lib/types/Atlas/WindowSize";
import {
  ContentPanel,
  ContentPanelContent,
  ContentPanelApplyCarouselOn,
} from "~/lib/types/Contentstack/GlobalFields/ContentPanel";
import { isNotNull } from "~/lib/utils/isNotNull";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Decorator],
});

const ContentPanelContentDecorator = (content: ContentPanelContent) => {
  if ("reference" in content) {
    return ContentPanelReferenceContentDecorator(content.reference);
  }

  if ("custom" in content) {
    return ContentPanelCustomContentDecorator(content.custom);
  }

  logger.warn("Content is of an invalid type", { content });
};

const contentstackContentPanelDecorator =
  () =>
  (contentPanel: ContentPanel): ContentPanelProps | undefined => {
    const decoratedContent = contentPanel.content
      .map(ContentPanelContentDecorator)
      .filter(isNotNull);

    const deviceColumnConfig: ContentPanelDeviceColumnConfig = {
      desktop: parseInt(contentPanel.display_desktop.split(" ")[0]),
      tablet: parseInt(contentPanel.display_tablet.split(" ")[0]),
    };

    // Empty array covers No Carousel case and used as default value
    const breakpointsToApplyCarouselOn: WindowSizeBreakpoint[] = [];

    switch (contentPanel.apply_carousel_on) {
      case ContentPanelApplyCarouselOn.Mobile:
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.XS);
        break;
      case ContentPanelApplyCarouselOn.TabletMobile:
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.XS);
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.SM);
        break;
      case ContentPanelApplyCarouselOn.DesktopTabletMobile:
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.XS);
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.SM);
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.MD);
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.LG);
        breakpointsToApplyCarouselOn.push(WindowSizeBreakpoint.XL);
    }

    return {
      breakpointsToApplyCarouselOn,
      displayType: contentPanel.display_type,
      content: decoratedContent,
      deviceColumnConfig,
    };
  };

export const ContentstackContentPanelDecorator =
  returnUndefinedOnDecoratorError(
    contentstackContentPanelDecorator,
    "ContentstackContentPanelDecorator"
  );
