import {
  ModularPage,
  Destination,
  Style,
  Theme,
  ContactUs,
  Category,
  TravelAlertsPage,
} from "~/lib/types/Contentstack";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Utils, logTags.Client.Contentstack],
});

type PageEntry =
  | Destination
  | ModularPage
  | Style
  | Theme
  | ContactUs
  | Category
  | TravelAlertsPage;

type BreadcrumbItems = { title: string; href: string }[];
type ExtractedBreadcrumbs = {
  breadcrumbs: BreadcrumbItems;
  isDestinationEntryPresent: boolean;
};

export function extractBreadcrumbsFromPageEntry(pageEntry: PageEntry) {
  const uids: string[] = [];
  uids.push(pageEntry.uid);
  const breadcrumbs: BreadcrumbItems = [
    {
      title: pageEntry.lead_info.display_name,
      href: pageEntry.url,
    },
  ];

  const extractedBreadcrumbs = _recursivelyExtractBreadcrumbs(pageEntry, [
    pageEntry.url,
  ]);

  breadcrumbs.push(...extractedBreadcrumbs.breadcrumbs);

  if (extractedBreadcrumbs.isDestinationEntryPresent) {
    breadcrumbs.push({
      title: "Destinations",
      href: "/destinations",
    });
  }

  breadcrumbs.push({
    title: "Home",
    href: "/",
  });

  return breadcrumbs.reverse();
}

function _recursivelyExtractBreadcrumbs(
  pageEntry: PageEntry,
  urls: string[],
  extractedBreadcrumbs: ExtractedBreadcrumbs = {
    breadcrumbs: [],
    isDestinationEntryPresent: false,
  }
) {
  if (_isParentFieldPresent(pageEntry)) {
    pageEntry.parent.parent.forEach(
      (entry: Exclude<PageEntry, Destination>) => {
        if (urls.includes(entry.url)) {
          logger.error(
            "Cyclic dependency detected while extracting breadcrumbs from page entry",
            extractedBreadcrumbs.breadcrumbs
          );
          return;
        }
        extractedBreadcrumbs.breadcrumbs.push({
          title: entry.lead_info!.display_name,
          href: entry.url,
        });

        _recursivelyExtractBreadcrumbs(
          entry,
          [...urls, entry.url],
          extractedBreadcrumbs
        );
      }
    );
  } else if (
    !_isParentFieldPresent(pageEntry) &&
    _isParentDestinationFieldPresent(pageEntry)
  ) {
    extractedBreadcrumbs.isDestinationEntryPresent = true;

    pageEntry.parent_destination.forEach((entry: Destination) => {
      if (urls.includes(entry.url)) {
        logger.error(
          "Cyclic dependency detected while extracting breadcrumbs from page entry",
          extractedBreadcrumbs.breadcrumbs
        );
        return;
      }
      extractedBreadcrumbs.breadcrumbs.push({
        title: entry.lead_info!.display_name,
        href: entry.url,
      });

      _recursivelyExtractBreadcrumbs(
        entry,
        [...urls, entry.url],
        extractedBreadcrumbs
      );
    });
  }
  return extractedBreadcrumbs;
}

function _isParentFieldPresent(
  pageEntry: PageEntry
): pageEntry is Exclude<PageEntry, Destination | ContactUs | Theme> {
  return Object.prototype.hasOwnProperty.call(pageEntry, "parent");
}

function _isParentDestinationFieldPresent(
  pageEntry: PageEntry
): pageEntry is Destination {
  return Object.prototype.hasOwnProperty.call(pageEntry, "parent_destination");
}
