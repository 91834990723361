import { TripListProps } from "../TripList/Props";
import { ContentstackTripListDecorator } from "../TripList/ContentstackTripListDecorator";
import { TripPanel } from "~/lib/types/Contentstack/GlobalFields/ProductPanel";
import { DecoratorOptions } from "~/lib/types/Options";

export const TripPanelDecorator =
  (decoratorOptions: DecoratorOptions) =>
  (productPanel: TripPanel): TripListProps | undefined => {
    return ContentstackTripListDecorator(
      decoratorOptions,
      false,
      productPanel.isTailorMadeTripCardVisible
    )(productPanel.products);
  };
