import { ContentstackVideoDecorator } from "../Video/ContentstackVideoDecorator";
import { VideoPanelProps, VideosProps } from "./Props";
import { isNotNull } from "~/lib/utils/isNotNull";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";

const contentstackVideoPanelDecorator =
  () =>
  (videos: VideosProps): VideoPanelProps | undefined => {
    if (!videos || !videos.video.length) {
      return undefined;
    }
    const decoratedVideo = videos.video.map((video) => {
      return ContentstackVideoDecorator()(video);
    });
    const filteredVideo = decoratedVideo[0];

    return isNotNull(filteredVideo)
      ? {
          video: filteredVideo,
        }
      : undefined;
  };

export const ContentstackVideoPanelDecorator = returnUndefinedOnDecoratorError(
  contentstackVideoPanelDecorator,
  "ContentstackVideoPanelDecorator"
);
