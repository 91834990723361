import { BannerDecorator } from "../PageBanner/ContentstackBannerDecorator";
import { ContentstackPanelsDecorator } from "../Panels/ContentstackPanelsDecorator";
import { PageDecorator } from "../Page/ContentstackPageDecorator";
import { PageProps } from "../Page/Props";
import { IntroductionDecorator } from "../Introduction/ContentstackIntroductionDecorator";
import { PageBreadcrumbDecorator } from "../PageBreadcrumb/PageBreadcrumbDecorator";
import { ModularPageProps } from "./Props";
import { ModularPage, SEO, Panel } from "~/lib/types/Contentstack";
import Locale from "~/lib/types/Locale";
import { getJsonLd } from "~/lib/mappers/JsonLdMapper";
import { DecoratorOptions } from "~~/lib/types/Options";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";

export const utag = (
  locale: Pick<Locale, "currencyCode" | "code">,
  path: string,
  pageType: string
): PageProps["utag"] => {
  return {
    page_type: pageType,
    page_path: path,
    currency: locale.currencyCode,
    country_code: locale.code,
  };
};

const contentstackModularPageContentDecorator =
  (options: DecoratorOptions) =>
  (pageEntry: ModularPage, panelsContent: Panel[]): ModularPageProps => {
    return {
      page: PageDecorator(options.locale)(
        utag(options.locale, options.path, options.pageType),
        getJsonLd(options)(),
        pageEntry.seo as SEO,
        options.path,
        PageBreadcrumbDecorator()(pageEntry),
        BannerDecorator()(pageEntry.lead_info)
      ),
      introduction: IntroductionDecorator()(pageEntry.lead_info),
      panels: panelsContent
        ? ContentstackPanelsDecorator(options)(panelsContent)
        : undefined,
    };
  };

export const ContentstackModularPageContentDecorator =
  returnUndefinedOnDecoratorError(
    contentstackModularPageContentDecorator,
    "ContentstackModularPageContentDecorator"
  );
