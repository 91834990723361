import { JsonRichTextDecorator } from "../JsonRichText/ContentstackJsonRichTextDecorator";
import { FaqPage, CTA } from "~/lib/types/Contentstack";
import { FaqProps } from "~/components/Faqs/Props";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";

const contentstackFaqsCtaDecorator = (faq: FaqPage): CTA | undefined => {
  if (!faq?.cta) {
    return undefined;
  }

  if (!faq.cta.href || !faq.cta.title) {
    return undefined;
  }

  if (faq.published_url && faq.url) {
    return {
      href: faq.url,
      title: faq.cta?.title,
    };
  }

  return faq.cta;
};

const contentstackFaqsDecorator =
  () =>
  (faqs: FaqPage[]): FaqProps[] | undefined => {
    if (faqs && faqs.length) {
      return faqs.map((faq: FaqPage) => {
        return {
          question: faq.question ?? "",
          answer: faq.answer ? JsonRichTextDecorator()(faq.answer) : undefined,
          cta: contentstackFaqsCtaDecorator(faq),
        };
      });
    }

    return undefined;
  };

export const ContentstackFaqsDecorator = returnUndefinedOnDecoratorError(
  contentstackFaqsDecorator,
  "ContentstackFaqsDecorator"
);
