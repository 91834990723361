import { ContentPanelContentProps } from "./Props";
import { ContentPanelCustomContent } from "~/lib/types/Contentstack/GlobalFields/ContentPanel";
import { imageMapper } from "~/lib/mappers/ImageMapper";
import { JsonRichTextDecorator } from "~/components/JsonRichText/ContentstackJsonRichTextDecorator";

export const ContentPanelCustomContentDecorator = (
  customContent: ContentPanelCustomContent
): ContentPanelContentProps | undefined => {
  if (!customContent?.display_name) {
    return undefined;
  }

  const customContentMedia = customContent?.picture?.[0];

  return {
    heading: customContent.display_name,
    description: customContent.description
      ? JsonRichTextDecorator()(customContent.description)
      : undefined,
    image:
      customContentMedia &&
      customContentMedia?.picture?.url &&
      customContentMedia?.alt_text
        ? imageMapper(
            customContentMedia?.picture?.url,
            customContentMedia?.alt_text,
            customContentMedia.picture?.dimension?.width,
            customContentMedia.picture?.dimension?.height
          )
        : undefined,
    ctaTitle: customContent.cta?.title || undefined,
    url: customContent.cta?.href || undefined,
  };
};
