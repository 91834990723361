import { TailorMadeCardProps } from "./Props";
import { imageMapper } from "~~/lib/mappers/ImageMapper";
import { BackgroundColor } from "~~/lib/types/Image";

export const TailorMadeCardHeader = "TAILOR-MADE TRIPS";

export type TailorMadeCardDecoratorOptions = {
  cardLayout: "horizontal" | "vertical";
};

export const TailorMadeCardDecorator =
  (options?: TailorMadeCardDecoratorOptions) => (): TailorMadeCardProps => {
    return {
      label: [TailorMadeCardHeader],
      description: "Build your dream adventure",
      url: "/tailor-made",
      image: {
        ...imageMapper(
          "https://images.contentstack.io/v3/assets/blt0de87ff52d9c34a8/bltb78607ed18fcfa5d/tailor-made-card-image.jpg",
          TailorMadeCardHeader.toLocaleLowerCase(),
          800,
          535
        ),
        backgroundColor: BackgroundColor.Map,
      },
      cardLayout: options?.cardLayout,
    };
  };
