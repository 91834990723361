import { TripCardProps } from "./Props";
import { Product } from "~/lib/types/Contentstack";
import { ContentstackStyleDecorator } from "~/lib/decorators/ContentstackStyleDecorator";
import { ContentstackLeadInfoDecorator } from "~/lib/decorators/ContentstackLeadInfoDecorator";
import { ContentstackPriceDecorator } from "~/lib/decorators/ContentstackPriceDecorator";
import { DecoratorOptions } from "~~/lib/types/Options";
import { imageMapper } from "~/lib/mappers/ImageMapper";
import { BackgroundColor } from "~~/lib/types/Image";

export const ContentstackTripCardDecorator =
  (options: DecoratorOptions) =>
  (productEntry: Product): TripCardProps => {
    const price =
      productEntry.from_price_discount &&
      ContentstackPriceDecorator(options)(productEntry.from_price_discount);

    const productMap =
      productEntry.map && productEntry.map.length
        ? productEntry.map[0]
        : undefined;

    return {
      name: ContentstackLeadInfoDecorator(options)(productEntry.lead_info)
        .title,
      url: productEntry.url,
      styles: productEntry.style
        ? productEntry.style
            .map((style) => ContentstackStyleDecorator(options)(style))
            .map((decoratedStyle) => decoratedStyle.displayName)
        : undefined,
      duration: productEntry.duration,
      price,
      map: productMap
        ? {
            ...imageMapper(
              productMap.picture?.url ?? "",
              productMap.alt_text ?? "",
              productMap.picture?.dimension?.width,
              productMap.picture?.dimension?.height
            ),
            backgroundColor: BackgroundColor.Map,
          }
        : undefined,
      showPrice: true,
      routerLink: true,
      code: productEntry.product_code,
    };
  };
