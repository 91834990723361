import { ContentstackTripCardDecorator } from "../TripCard/ContentstackTripCardDecorator";
import { TailorMadeCardDecorator } from "../TailorMadeCard/TailorMadeCardDecorator";
import { TripListProps } from "./Props";
import { DecoratorOptions } from "~~/lib/types/Options";
import { Product } from "~~/lib/types/Contentstack";
import { instrument } from "~~/lib/utils/logger/instrument";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { JsonRichTextProps } from "~/components/JsonRichText/Props";

const contentstackTripListDecorator = (
  decoratorOptions: DecoratorOptions,
  showSearchButton: boolean,
  isTailorMadeTripCardVisible: boolean,
  destinationDisplayName?: string,
  title?: string,
  description?: JsonRichTextProps
) =>
  instrument(
    "contentstackTripListDecorator",
    (products: Product[]): TripListProps | undefined => {
      if (
        (!products || products.length === 0) &&
        !isTailorMadeTripCardVisible
      ) {
        return undefined;
      }

      const trips = products.map((productEntry) => {
        return ContentstackTripCardDecorator(decoratorOptions)(productEntry);
      });

      return {
        showSearchButton,
        tailorMadeTripCard: isTailorMadeTripCardVisible
          ? TailorMadeCardDecorator()()
          : undefined,
        trips,
        destinationDisplayName,
        title,
        description,
      };
    }
  );
export const ContentstackTripListDecorator = returnUndefinedOnDecoratorError(
  contentstackTripListDecorator,
  "ContentstackTripListDecorator"
);
