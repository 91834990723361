import { ReviewReferencePanelDecorator } from "../ReviewReferencePanel/ReviewReferencePanelDecorator";
import { ReviewPanelDecorator } from "../ReviewPanel/ReviewPanelDecorator";
import { ContentstackHeadingPanelDecorator } from "../HeadingPanel/ContentstackHeadingPanelDecorator";
import { ContentstackCallToActionPanelDecorator } from "../CallToActionPanel/ContentstackCallToActionPanelDecorator";
import { ContentstackFormPanelDecorator } from "../FormPanel/ContentstackFormPanelDecorator";
import { JsonRichTextDecorator } from "../JsonRichText/ContentstackJsonRichTextDecorator";
import { ContentstackMediaPanelDecorator } from "../MediaPanel/ContentstackMediaPanelDecorator";
import { PanelsProps, PanelType } from "./Type";
import { ContentstackContentPanelDecorator } from "~/components/ContentPanel/ContentstackContentPanelDecorator";
import { DecoratorOptions } from "~/lib/types/Options";
import { PanelWithAdditionalContent } from "~/lib/types/Contentstack";
import { ContentstackVideoPanelDecorator } from "~~/components/VideoPanel/ContentstackVideoPanelDecorator";
import { ContentstackUniqueSellingPointPanelDecorator } from "~~/components/UniqueSellingPointPanel/ContentstackUniqueSellingPointPanelDecorator";
import { returnUndefinedOnDecoratorError } from "~~/lib/utils/error/returnUndefinedOnDecoratorError";
import { TripPanelDecorator } from "~/components/TripPanel/TripPanelDecorator";
import { removeNulls } from "~~/lib/utils/removeNulls";
import { ContentstackFAQsPanelDecorator } from "~/components/FAQsPanel/ContentstackFAQsPanelDecorator";

const panelMapper = (
  panel: PanelWithAdditionalContent,
  decoratorOptions: DecoratorOptions
): PanelType | undefined => {
  if ("rich_text_panel" in panel) {
    return {
      type: "JsonRichText",
      props: JsonRichTextDecorator()(panel.rich_text_panel.description),
    };
  }

  if ("call_to_action_panel" in panel) {
    return {
      type: "CallToActionPanel",
      props: ContentstackCallToActionPanelDecorator()(
        panel.call_to_action_panel
      ),
    };
  }

  if ("form_panel" in panel) {
    return {
      type: "FormPanel",
      props: ContentstackFormPanelDecorator()(panel.form_panel),
    };
  }

  if ("content_panel" in panel) {
    return {
      type: "ContentPanel",
      props: ContentstackContentPanelDecorator()(panel.content_panel),
    };
  }

  if ("usp_panel" in panel) {
    return {
      type: "UniqueSellingPointPanel",
      props: ContentstackUniqueSellingPointPanelDecorator()(
        panel.usp_panel.usp
      ),
    };
  }

  if ("video_panel" in panel) {
    return {
      type: "VideoPanel",
      props: ContentstackVideoPanelDecorator()(panel.video_panel),
    };
  }

  if ("heading" in panel) {
    return {
      type: "HeadingPanel",
      props: ContentstackHeadingPanelDecorator()(panel.heading),
    };
  }

  if ("section_break" in panel) {
    return {
      type: "SectionBreakPanel",
      props: undefined,
    };
  }

  if ("trips" in panel) {
    return {
      type: "ProductReferencePanel",
      props: panel,
    };
  }

  if ("media_panel" in panel) {
    return {
      type: "MediaPanel",
      props: ContentstackMediaPanelDecorator()(panel.media_panel),
    };
  }

  if ("blogPosts" in panel) {
    return {
      type: "BlogPanel",
      props: panel,
    };
  }

  if ("product_panel" in panel) {
    if ("products" in panel.product_panel) {
      return {
        type: "TripPanel",
        props: TripPanelDecorator(decoratorOptions)(panel.product_panel),
      };
    }
  }

  if ("review_panel" in panel) {
    return {
      type: "ReviewPanel",
      props: ReviewPanelDecorator()(panel.review_panel),
    };
  }

  if ("review_reference_panel" in panel) {
    return {
      type: "ReviewReferencePanel",
      props: ReviewReferencePanelDecorator()(panel.review_reference_panel),
    };
  }

  if ("faq_panel" in panel) {
    return {
      type: "FAQsPanel",
      props: ContentstackFAQsPanelDecorator()(panel.faq_panel.faq),
    };
  }
};

const contentstackPanelsDecorator =
  (decoratorOptions: DecoratorOptions) =>
  (panelEntry: PanelWithAdditionalContent[]): PanelsProps => ({
    panels: panelEntry
      .map((panel) => {
        return panelMapper(panel, decoratorOptions);
      })
      .filter(removeNulls),
  });

export const ContentstackPanelsDecorator = returnUndefinedOnDecoratorError(
  contentstackPanelsDecorator,
  "ContentstackPanelsDecorator"
);
