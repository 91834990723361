import { JsonRte } from "lib/types/Contentstack";
import { ContentPanelContentProps } from "./Props";
import { ContentPanelReferenceContent } from "~/lib/types/Contentstack/GlobalFields/ContentPanel";
import { imageMapper } from "~/lib/mappers/ImageMapper";
import { JsonRichTextDecorator } from "~/components/JsonRichText/ContentstackJsonRichTextDecorator";

export const ContentPanelReferenceContentDecorator = (
  referenceContent: ContentPanelReferenceContent
): ContentPanelContentProps | undefined => {
  const {
    override_description: overrideDescription,
    override_display_name: overrideDisplayName,
    reference,
  } = referenceContent;

  if (
    !reference ||
    reference?.length === 0 ||
    (!reference?.[0].lead_info?.thumbnail_image?.[0] &&
      !JsonRichTextDecorator()(
        reference?.[0].lead_info?.short_description as JsonRte
      ) &&
      !reference?.[0].lead_info?.display_name &&
      !reference?.[0]?.url)
  ) {
    return undefined;
  }

  const description =
    JsonRichTextDecorator()(overrideDescription!) ||
    JsonRichTextDecorator()(
      reference?.[0].lead_info?.short_description as JsonRte
    );

  const thumbnailImage = reference?.[0].lead_info?.thumbnail_image?.[0];

  return {
    heading: overrideDisplayName || reference?.[0].lead_info?.display_name,
    description,
    image:
      thumbnailImage &&
      imageMapper(
        thumbnailImage.picture?.url,
        thumbnailImage.alt_text,
        thumbnailImage.picture?.dimension?.width,
        thumbnailImage.picture?.dimension?.height
      ),
    ctaTitle: referenceContent?.cta_title,
    url: reference?.[0]?.url,
  };
};
