import { ReviewPanelProps } from "./Props";
import { ReviewPanelWithAdditionalContent } from "~/lib/types/Contentstack";

export const ReviewPanelDecorator =
  () =>
  (
    reviewPanel: ReviewPanelWithAdditionalContent
  ): ReviewPanelProps | undefined => {
    if (!reviewPanel?.reviewAggregate || !reviewPanel?.reviews) {
      return undefined;
    }
    return reviewPanel;
  };
