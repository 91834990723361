import { ReviewReferencePanelProps } from "./Props";
import { ReviewReferencePanelWithAdditionalContent } from "~~/lib/types/Contentstack/GlobalFields/ReviewReferencePanel";

export const ReviewReferencePanelDecorator =
  () =>
  (
    reviewPanel: ReviewReferencePanelWithAdditionalContent
  ): ReviewReferencePanelProps | undefined => {
    if (!reviewPanel?.reviewAggregate || !reviewPanel?.reviews) {
      return undefined;
    }
    return reviewPanel;
  };
