import { isNotNull } from "../utils/isNotNull";
import { stripHostNameFromURL } from "../utils/stripHostNameFromURL";
import { parseURL } from "../utils/parseURL";
import Image, { BannerAspectRatio, ImageCropInfo } from "~/lib/types/Image";

const imageSrcsetSizes = [
  "75w",
  "160w",
  "320w",
  "480w",
  "640w",
  "800w",
  "960w",
  "1080w",
  "1240w",
  "1400w",
  "1920w",
  "2560w",
];

export const imageMapper = (
  src: string,
  alt: string,
  actualWidth?: number,
  actualHeight?: number,
  cropInfo?: ImageCropInfo
): Image => {
  return {
    src: buildLowQualitySrc(src),
    alt,
    width: actualWidth,
    height: actualHeight,
    srcSet: buildSrcset(src, actualWidth, actualHeight, cropInfo),
  };
};

export const imageMapperObj = ({
  src,
  alt,
  width,
  height,
}: {
  src: string;
  alt: string;
  width: number;
  height: number;
}) => imageMapper(src, alt, width, height);

export const parseBannerAspectRatio = (aspectRatio: BannerAspectRatio) => {
  const aspectRatioParts = aspectRatio.split(":");
  return {
    x: parseInt(aspectRatioParts[0]),
    y: parseInt(aspectRatioParts[1]),
  };
};

export const ensureArtDirectionIsNotNeeded = (cropInfo: ImageCropInfo) => {
  if (
    !Object.values(cropInfo.byAspectRatio).every(
      (value) => value === cropInfo.byAspectRatio.xs
    )
  ) {
    throw new Error(
      "Image cropping isn't supported when the aspect ratio is different across devices"
    );
  }
};

export const getContentstackCropQueryParams = (
  actualWidth: number,
  cropInfo: ImageCropInfo
): string => {
  let contentstackCropQueryParam = "";

  ensureArtDirectionIsNotNeeded(cropInfo);

  const aspectRatio = parseBannerAspectRatio(cropInfo.byAspectRatio.xs);
  const stipulatedHeight = Math.round(
    (actualWidth * aspectRatio.y) / aspectRatio.x
  );
  contentstackCropQueryParam = `${actualWidth},${stipulatedHeight}`;

  if (cropInfo.offset === "center") {
    contentstackCropQueryParam = `${contentstackCropQueryParam},offset-x50,offset-y50`;
  } else if (
    cropInfo.offset &&
    cropInfo.offset.xPercentage &&
    cropInfo.offset.yPercentage
  ) {
    contentstackCropQueryParam = `${contentstackCropQueryParam},offset-x${cropInfo.offset.xPercentage},offset-y${cropInfo.offset.yPercentage}`;
  }

  return contentstackCropQueryParam;
};

const buildSrcset = (
  src: string,
  actualWidth?: number,
  actualHeight?: number,
  cropInfo?: ImageCropInfo
): Array<{ src: string; size: string }> => {
  const url = parseURL(src);

  const urlHasCropParam = url instanceof URL && url.search.includes("crop=");

  if (urlHasCropParam && cropInfo) {
    throw new Error(
      "Crop parameter is found to be present in source URL. Can't crop the image any further."
    );
  }

  if (url) {
    return imageSrcsetSizes
      .map((size: string) => {
        const stipulatedWidth = parseInt(size.slice(0, -1));

        if (actualWidth && stipulatedWidth > actualWidth) {
          return null;
        }

        url.searchParams.delete("width");
        url.searchParams.delete("height");
        url.searchParams.delete("quality");
        url.searchParams.delete("format");
        url.searchParams.delete("auto");
        if (!urlHasCropParam) {
          url.searchParams.delete("crop");
        }

        url.searchParams.append("width", `${stipulatedWidth}`);

        if (!urlHasCropParam && actualWidth && actualHeight && cropInfo) {
          url.searchParams.append(
            "crop",
            getContentstackCropQueryParams(actualWidth, cropInfo)
          );
        }

        url.searchParams.append("quality", "75");
        url.searchParams.append("format", "pjpg");
        url.searchParams.append("auto", "webp");

        return {
          src: stripHostNameFromURL(url) || "",
          size,
        };
      })
      .filter(isNotNull);
  }
  return [];
};

const buildLowQualitySrc = (src: string): string => {
  const url = parseURL(src);

  if (url) {
    url.searchParams.delete("width");
    url.searchParams.delete("height");
    url.searchParams.append("width", "75");
    url.searchParams.append("quality", "35");
    url.searchParams.append("blur", "1");
    url.searchParams.append("format", "pjpg");
    url.searchParams.append("auto", "webp");

    return stripHostNameFromURL(url) || "";
  } else {
    return src;
  }
};
